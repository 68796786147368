import { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { Flex } from 'antd'

import { FeaturedCollectionContext } from '@/context/FeaturedCollectionContext'
import CollectionThumbnail from './CollectionThumbnail'
import NavigateButton from './NavigateButton'

import 'swiper/css'

export default function FeaturedCollections() {
  const { collections, currentIndex, setIndex } = useContext(
    FeaturedCollectionContext,
  )

  return (
    <Flex className="h-[736px]" justify="center">
      <div className="h-full border border-white/[0.12] overflow-hidden relative px-[24px] mx-auto">
        <Swiper
          spaceBetween={15}
          slidesPerView={3}
          onSlideChange={(e) =>
            setIndex((e.realIndex + 1) % collections.length)
          }
          direction="vertical"
          loop={true}
          className="w-max h-[756px] !mx-0 !z-0"
          navigation={{
            nextEl: '#featured-collection-next-btn',
            prevEl: '#featured-collection-prev-btn',
          }}
          modules={[Navigation]}
        >
          {collections.map((collection, index) => (
            <SwiperSlide key={collection.id + index}>
              <CollectionThumbnail
                active={currentIndex === index}
                collectionData={collection}
                index={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <NavigateButton id="featured-collection-next-btn" />
        <NavigateButton id="featured-collection-prev-btn" isPrev />
      </div>
    </Flex>
  )
}
