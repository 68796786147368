import { createContext } from "react";

import { FeaturedCollection, StrapiContent } from "@/types";

type FeaturedCollectionContextValue = {
  collections: StrapiContent<FeaturedCollection>[];
  currentIndex: number;
  setIndex: (index: number) => void;
};

export const FeaturedCollectionContext =
  createContext<FeaturedCollectionContextValue>({
    collections: [],
    currentIndex: 0,
    setIndex: () => {},
  });
