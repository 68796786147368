import { Typography } from "antd";

import "./index.scss";

type SectionTitleProps = {
  title: string;
};

const SectionTitle = ({ title }: SectionTitleProps) => {
  return (
    <Typography.Title
      level={3}
      className="section-title inline !text-[42px] italic uppercase"
    >
      {title}
    </Typography.Title>
  );
};

export default SectionTitle;
