import { BaseComponentProps } from "@/types";

export const VerifiedIcon = ({ className }: BaseComponentProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M21.56 10.7386L20.2 9.15859C19.94 8.85859 19.73 8.29859 19.73 7.89859V6.19859C19.73 5.13859 18.86 4.26859 17.8 4.26859H16.1C15.71 4.26859 15.14 4.05859 14.84 3.79859L13.26 2.43859C12.57 1.84859 11.44 1.84859 10.74 2.43859L9.17 3.80859C8.87 4.05859 8.3 4.26859 7.91 4.26859H6.18C5.12 4.26859 4.25 5.13859 4.25 6.19859V7.90859C4.25 8.29859 4.04 8.85859 3.79 9.15859L2.44 10.7486C1.86 11.4386 1.86 12.5586 2.44 13.2486L3.79 14.8386C4.04 15.1386 4.25 15.6986 4.25 16.0886V17.7986C4.25 18.8586 5.12 19.7286 6.18 19.7286H7.91C8.3 19.7286 8.87 19.9386 9.17 20.1986L10.75 21.5586C11.44 22.1486 12.57 22.1486 13.27 21.5586L14.85 20.1986C15.15 19.9386 15.71 19.7286 16.11 19.7286H17.81C18.87 19.7286 19.74 18.8586 19.74 17.7986V16.0986C19.74 15.7086 19.95 15.1386 20.21 14.8386L21.57 13.2586C22.15 12.5686 22.15 11.4286 21.56 10.7386ZM16.16 10.1086L11.33 14.9386C11.1894 15.079 10.9988 15.1579 10.8 15.1579C10.6013 15.1579 10.4106 15.079 10.27 14.9386L7.85 12.5186C7.71052 12.3775 7.6323 12.187 7.6323 11.9886C7.6323 11.7902 7.71052 11.5997 7.85 11.4586C8.14 11.1686 8.62 11.1686 8.91 11.4586L10.8 13.3486L15.1 9.04859C15.39 8.75859 15.87 8.75859 16.16 9.04859C16.45 9.33859 16.45 9.81859 16.16 10.1086Z"
        fill="#D8FF76"
      />
    </svg>
  );
};
