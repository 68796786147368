import AppContainer from "@/layouts/AppContainer";
import TempLayout from "@/layouts/TempLayout";
import AllCollections from "@/views/Home/AllCollections/AllCollections";
import Banner from "@/views/Home/Banner/Banner";

export default function Home() {
  return (
    <TempLayout>
      <Banner />
      <div className="w-full pt-8">
        <AppContainer>
          <AllCollections />
        </AppContainer>
      </div>
    </TempLayout>
  );
}
