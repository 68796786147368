import { Flex, Image, Typography } from 'antd'

import { CollectionTableValue } from './Table'
import { VerifiedIcon } from '@/components/icons'

type Props = {
  data: CollectionTableValue
}

const CollectionInfo = ({ data }: Props) => {
  return (
    <Flex align="center" gap={16}>
      <div>
        <Image
          width={80}
          height={80}
          alt="collection-thumbnail"
          src={data.avatar}
          preview={false}
          className="rounded"
        />
      </div>
      <Typography.Text className="text-lg">
        {data.name} <VerifiedIcon className="inline" />
      </Typography.Text>
    </Flex>
  )
}
export default CollectionInfo
