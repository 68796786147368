import { Col, Row } from 'antd'

import SectionTitle from '../SectionTitle'
import AllCollectionTable from './Table'

const AllCollections = () => {
  return (
    <Row gutter={[0, 48]}>
      <Col span={24}>
        <SectionTitle title="All Collections" />
      </Col>
      <Col span={24} className="px-5 py-6 border-[#94949433] border rounded-xl">
        <AllCollectionTable />
      </Col>
    </Row>
  )
}

export default AllCollections
