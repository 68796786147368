import { useQuery } from "@tanstack/react-query";

import { nftCollectionService } from "@/services/nft-collection.service";

import { extractStrapiOneQueryResult } from "@/utils";
import { QUERY_KEY } from "@/constants";

export const useNftCollectionById = (id: string) => {
  const result = useQuery({
    queryKey: [QUERY_KEY.GET_NFT_COLLECTION_BY_ID, id],
    queryFn: () => nftCollectionService.getById(id),
  });
  return extractStrapiOneQueryResult(result);
};
