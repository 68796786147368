import clsx from "clsx";
import { Button } from "antd";

import { ArrowIcon } from "@/components/icons";

type NavigateButtonProps = {
  id: string;
  isPrev?: boolean;
};

export default function NavigateButton({ id, isPrev }: NavigateButtonProps) {
  return (
    <Button
      className={clsx(
        "absolute left-1/2 -translate-x-1/2 z-[1] bg-[#101010]/20 backdrop-blur-md text-primary w-8 h-8 rounded-full flex justify-center align-middle p-0 border-none hover:!bg-[#101010]/40",
        isPrev ? "top-28 rotate-180" : "bottom-12"
      )}
      id={id}
    >
      <ArrowIcon />
    </Button>
  );
}
