import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { Col, Flex, Image, Row } from 'antd'

import { FeaturedCollectionContext } from '@/context/FeaturedCollectionContext'
import AppContainer from '@/layouts/AppContainer'
import CollectionInfo from './CollectionInfo'
import FeaturedCollections from './FeaturedCollections'

import { useFeaturedCollections } from '@/hooks/featured-collection/useFeaturedCollections'

export default function Banner() {
  const [index, setIndex] = useState(1)
  const { data } = useFeaturedCollections()

  const collections = useMemo(() => {
    if (data.length <= 3) return [...data, ...data]
    return data
  }, [data])

  if (!collections.length) return <div className="w-full h-[736px]" />

  return (
    <FeaturedCollectionContext.Provider
      value={{
        currentIndex: index,
        collections,
        setIndex,
      }}
    >
      <div
        className={clsx('bg-cover bg-no-repeat home relative')}
        style={
          collections[index].coverImage && {
            backgroundImage: `url("${collections[index].coverImage!.url}")`,
          }
        }
      >
        {!!collections[index].coverVideo?.url && (
          <div className="absolute inset-0">
            <video muted loop autoPlay className="!w-full !h-full object-cover">
              <source
                src={collections[index].coverVideo?.url}
                type="video/mp4"
              />
            </video>
          </div>
        )}
        <div className="w-full home--banner relative">
          <AppContainer>
            <Row className="h-[736px]" gutter={[20, 0]}>
              <Col span={10}>
                <Flex vertical justify="flex-end" className="h-full">
                  <div>
                    <Image
                      preview={false}
                      src={collections[index].avatar.url}
                      alt="collection-avatar"
                      wrapperClassName="block"
                    />
                  </div>
                </Flex>
              </Col>
              <Col span={8}>
                <Flex align="center" className="h-full pt-20">
                  <CollectionInfo />
                </Flex>
              </Col>
              <Col span={6}>
                <FeaturedCollections />
              </Col>
            </Row>
          </AppContainer>
        </div>
        <div className="h-28 w-full absolute -bottom-8 bg-[linear-gradient(180deg,rgba(18,19,15,0.00)_16.48%,#0A0A0A_42.23%)]" />
      </div>
    </FeaturedCollectionContext.Provider>
  )
}
