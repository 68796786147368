import { StrapiContent, StrapiMedia } from "./strapi.type";
import { GetListDto } from "./common.type";
import { NftCollection } from "./nft-collection.type";

export type FeaturedCollection = {
  avatar: StrapiContent<StrapiMedia>;
  coverImage?: StrapiContent<StrapiMedia>;
  coverVideo?: StrapiContent<StrapiMedia>;
  priority: number;
  nftCollection: StrapiContent<NftCollection>;
};

export enum FeaturedCollectionSort {
  PRIORITY = "FEATURED_COLLECTION_SORT:PRIORITY",
}

export type GetListFeaturedCollectionDto = GetListDto<FeaturedCollectionSort>;
