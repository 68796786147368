import { Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { CollectionTableValue } from './Table'
import CollectionInfo from './CollectionInfo'
import Header from './Header'
import Price from './Price'

export const CollectionColumns: ColumnsType<CollectionTableValue> = [
  {
    title: 'COLLECTION',
    dataIndex: '',
    render: (_, value) => <CollectionInfo data={value} />,
    width: '40%',
  },
  {
    title: <Header title="FLOOR PRICE" />,
    dataIndex: 'floorPrice',
    render: (floorPrice: string) => <Price value={floorPrice} />,
    width: '15%',
  },
  {
    title: <Header title="VOLUME" />,
    dataIndex: 'volume',
    render: (volume: string) => <Price value={volume} />,
    width: '15%',
  },
  {
    title: <Header title="SUPPLY" />,
    dataIndex: 'supply',
    render: (supply: number) => (
      <Typography.Text className="!text-md inline-block h-[52px]">
        {supply}
      </Typography.Text>
    ),
    width: '15%',
  },
  {
    title: <Header title="OWNERS" />,
    dataIndex: 'owners',
    render: (owners: number) => (
      <Typography.Text className="!text-md inline-block h-[52px]">
        {owners}
      </Typography.Text>
    ),
    width: '15%',
  },
]
