import { PropsWithChildren } from "react";

import { Layout } from "antd";

type TempLayoutProps = PropsWithChildren;

// Note: temp layout, will replace by Daniel's package
export default function TempLayout({ children }: TempLayoutProps) {
  return (
    <Layout>
      <div className="fixed h-[76px] w-screen bg-black/80 z-10 backdrop-blur-lg">
        <p className="text-center">Đây là Header</p>
      </div>
      <div>
        <div className="w-[56px] h-screen left-0 top-0 absolute bg-black/70 py-48 z-10">
          Đây là sidebar
        </div>
        <div>{children}</div>
      </div>
    </Layout>
  );
}
