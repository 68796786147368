import { GetListDto } from "./common.type";
import { StrapiContent, StrapiMedia } from "./strapi.type";

export type NftCollection = {
  address: string;
  chain: string;
  description: string;
  locale: string;
  name: string;
  slug: string;
  cover: StrapiContent<StrapiMedia>;
  thumbnail: StrapiContent<StrapiMedia>;
};

export enum NftCollectionSort {
  PRIORITY = "NFT_COLLECTION_SORT:PRIORITY",
}

export type GetListNftCollectionDto = GetListDto<NftCollectionSort>;
