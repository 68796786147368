import { Flex, Typography } from 'antd'

import { SortIcon } from '@/components/icons'

type HeaderProps = {
  title: string
}

const Header = ({ title }: HeaderProps) => {
  return (
    <Flex gap={4} className="cursor-pointer">
      <Typography.Text type="secondary" className="uppercase">
        {title}
      </Typography.Text>
      <div className="relative">
        <SortIcon size={15} className="absolute -top-[1.5px]" />
        <SortIcon
          className="absolute rotate-180 opacity-50 -bottom-[1.5px]"
          size={15}
        />
      </div>
    </Flex>
  )
}

export default Header
