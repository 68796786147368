import qs from "qs";
import { strapiAxios } from "./axios";

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/constants";
import { StrapiResponseList, StrapiResponseOne } from "@/types";
import {
  GetListNftCollectionDto,
  NftCollection,
  NftCollectionSort,
} from "@/types/nft-collection.type";

const NFT_COLLECTION_CONTROLLER = "/nft-collections";

class NftCollectionService {
  async getList({
    sort = NftCollectionSort.PRIORITY,
    pageSize = DEFAULT_PAGE_SIZE,
    page = DEFAULT_PAGE,
  }: GetListNftCollectionDto = {}) {
    const sortValue: string[] = [];
    switch (sort) {
      case NftCollectionSort.PRIORITY:
      default:
        // Note: larger priority number will be displayed first
        sortValue.push("priority:desc");
        break;
    }
    const query = qs.stringify(
      {
        sort: sortValue,
        pagination: {
          page,
          pageSize,
        },
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );

    const { data } = await strapiAxios.get<StrapiResponseList<NftCollection>>(
      `${NFT_COLLECTION_CONTROLLER}?${query}`
    );

    return data;
  }

  async getById(id: string) {
    const query = qs.stringify(
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    const { data } = await strapiAxios.get<StrapiResponseOne<NftCollection>>(
      `${NFT_COLLECTION_CONTROLLER}/${id}?${query}`
    );

    return data;
  }
}

export const nftCollectionService = new NftCollectionService();
