import qs from "qs";

import { strapiAxios } from "./axios";

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/constants";
import {
  FeaturedCollection,
  FeaturedCollectionSort,
  GetListFeaturedCollectionDto,
} from "@/types/featured-collection.type";
import { StrapiResponseList } from "@/types";

const FEATURED_COLLECTION_CONTROLLER = "/dojo-featured-collections";

class FeaturedCollectionService {
  async getList({
    sort = FeaturedCollectionSort.PRIORITY,
    pageSize = DEFAULT_PAGE_SIZE,
    page = DEFAULT_PAGE,
  }: GetListFeaturedCollectionDto = {}) {
    const sortValue: string[] = [];
    switch (sort) {
      case FeaturedCollectionSort.PRIORITY:
      default:
        // Note: larger priority number will be displayed first
        sortValue.push("priority:desc");
        break;
    }
    const query = qs.stringify(
      {
        sort: sortValue,
        pagination: {
          page,
          pageSize,
        },
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );

    const { data } = await strapiAxios.get<
      StrapiResponseList<FeaturedCollection>
    >(`${FEATURED_COLLECTION_CONTROLLER}?${query}`);

    return data;
  }
}

export const featuredCollectionService = new FeaturedCollectionService();
