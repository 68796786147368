import { useContext } from "react";
import { useSwiper } from "swiper/react";
import clsx from "clsx";
import { Image } from "antd";

import { FeaturedCollectionContext } from "@/context/FeaturedCollectionContext";

import { useNftCollectionById } from "@/hooks/nft-collection/useNftCollectionById";

import { FeaturedCollection, StrapiContent } from "@/types";
import "./index.scss";

type CollectionThumbnailProps = {
  active?: boolean;
  collectionData: StrapiContent<FeaturedCollection>;
  index: number;
};

export default function CollectionThumbnail({
  active,
  collectionData,
  index,
}: CollectionThumbnailProps) {
  const swiper = useSwiper();
  const { data } = useNftCollectionById(
    collectionData.nftCollection.documentId
  );
  const { collections } = useContext(FeaturedCollectionContext);

  const onClickThumbnail = () => {
    swiper.slideToLoop((index + collections.length - 1) % collections.length);
  };

  if (!data) return null;

  return (
    <div
      className={clsx(
        "hexagon cursor-pointer h-[242px] w-[242px]",
        !active && "opacity-50"
      )}
      onClick={onClickThumbnail}
    >
      <Image
        preview={false}
        src={data.thumbnail.url}
        width={"100%"}
        className="object-cover aspect-square z-[-1] relative"
        alt="collection-thumbnail"
      />
    </div>
  );
}
