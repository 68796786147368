export const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12.0008 13.4497L7.05104 8.5L5.63684 9.91421L12.0008 16.2782L18.3647 9.91421L16.9505 8.5L12.0008 13.4497Z"
        fill="#D8FF76"
      />
    </svg>
  );
};
