import { BaseComponentProps } from '@/types'

type SortIconProps = {
  size?: number
} & BaseComponentProps

export const SortIcon = ({ className, size }: SortIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="6 0 10 13"
      fill="none"
      className={className}
    >
      <path
        d="M10.6536 3.6C10.8075 3.33333 11.1925 3.33333 11.3464 3.6L14.5507 9.15C14.7047 9.41667 14.5122 9.75 14.2043 9.75H7.79571C7.48779 9.75 7.29534 9.41667 7.4493 9.15L10.6536 3.6Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}
