import { UseQueryResult } from "@tanstack/react-query";

import { StrapiResponseList, StrapiResponseOne } from "@/types";

export const extractStrapiListQueryResult = <T>(
  queryResult: UseQueryResult<StrapiResponseList<T>, Error>
) => {
  const { data, ...rest } = queryResult;
  return {
    ...rest,
    data: data?.data || [],
    total: data?.meta.pagination.total || 0,
  };
};

export const extractStrapiOneQueryResult = <T>(
  queryResult: UseQueryResult<StrapiResponseOne<T>, Error>
) => {
  const { data, ...rest } = queryResult;
  return {
    ...rest,
    data: data?.data,
  };
};
