import { Button, Col, Row, Table } from 'antd'

import { CollectionColumns } from './Column'
import { ViewMoreIcon } from '@/components/icons'

import './index.scss'

export type CollectionTableValue = {
  avatar: string
  name: string
  floorPrice: string
  volume: string
  supply: number
  owners: number
}

const data: CollectionTableValue[] = [
  {
    avatar: 'https://bit.ly/3O8PkO8',
    name: 'Space3 Genesis Elite Nft Regen Service Oi gioi oi neu dai hon',
    floorPrice: '12',
    volume: '3,000,000',
    supply: 1000,
    owners: 5000,
  },
  {
    avatar: 'https://bit.ly/3O8PkO8',
    name: 'Space3 Genesis',
    floorPrice: '12',
    volume: '3,000,000',
    supply: 1000,
    owners: 5000,
  },
  {
    avatar: 'https://bit.ly/3O8PkO8',
    name: 'Space3 Genesis',
    floorPrice: '12',
    volume: '3,000,000',
    supply: 1000,
    owners: 5000,
  },
  {
    avatar: 'https://bit.ly/3O8PkO8',
    name: 'Space3 Genesis',
    floorPrice: '12',
    volume: '3,000,000',
    supply: 1000,
    owners: 5000,
  },
  {
    avatar: 'https://bit.ly/3O8PkO8',
    name: 'Space3 Genesis',
    floorPrice: '12',
    volume: '3,000,000',
    supply: 1000,
    owners: 5000,
  },
]

export default function AllCollectionTable() {
  return (
    <Row justify="center" gutter={[0, 16]}>
      <Col span={24}>
        <Table
          dataSource={data}
          columns={CollectionColumns}
          pagination={false}
          className="all-collections-table"
          tableLayout="fixed"
        />
      </Col>
      <Col>
        <Button className="!bg-[unset] border-none hover:border-none shadow-none">
          View more <ViewMoreIcon />
        </Button>
      </Col>
    </Row>
  )
}
