import { useQuery } from "@tanstack/react-query";

import { featuredCollectionService } from "@/services/featured-collection.service";

import { extractStrapiListQueryResult } from "@/utils";
import { QUERY_KEY } from "@/constants";
import { GetListFeaturedCollectionDto } from "@/types/featured-collection.type";

export const useFeaturedCollections = (dto?: GetListFeaturedCollectionDto) => {
  const result = useQuery({
    queryKey: [QUERY_KEY.GET_FEATURED_COLLECTIONS, dto],
    queryFn: () => featuredCollectionService.getList(dto),
  });
  return extractStrapiListQueryResult(result);
};
