import { Flex, Typography } from 'antd'

import { A8TokenIcon } from '@/components/icons'

type PriceProps = {
  value: string
}

const Price = ({ value }: PriceProps) => {
  return (
    <Flex gap={6} vertical>
      <Flex gap={5} align="center">
        <Typography.Text className="text-md leading-none">
          {value}
        </Typography.Text>
        <A8TokenIcon size={16} />
      </Flex>
      <div>
        <Typography.Text className="text-sm font-thin text-white/40">
          ~$12,12
        </Typography.Text>
      </div>
    </Flex>
  )
}

export default Price
