import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Flex } from "antd";

type AppContainerProps = PropsWithChildren & {
  className?: string;
};

export default function AppContainer({
  children,
  className,
}: AppContainerProps) {
  return (
    <Flex className={clsx("ml-[55px]", className)} justify="center">
      <div className="max-w-[1305px] w-full">{children}</div>
    </Flex>
  );
}
