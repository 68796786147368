import Link from "next/link";
import { useContext } from "react";
import { Button, Col, Flex, Row, Space, Typography } from "antd";

import { FeaturedCollectionContext } from "@/context/FeaturedCollectionContext";
import { A8TokenIcon, OpenNewTabIcon } from "@/components/icons";

import { APP_ROUTES } from "@/constants";

export default function CollectionInfo() {
  const { collections, currentIndex } = useContext(FeaturedCollectionContext);
  const { nftCollection } = collections[currentIndex];

  return (
    <Row align="middle" gutter={[0, 20]}>
      <Col span={24}>
        <Typography.Text className="text-white text-[50px] font-extrabold uppercase leading-[1.2]">
          {nftCollection.name}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Flex justify="space-between">
          <Space size={24}>
            <Space direction="vertical" size={0}>
              <Typography.Text type="secondary">Floor price</Typography.Text>
              <Flex gap={6}>
                <Typography.Text className="font-medium">1,000</Typography.Text>
                <A8TokenIcon size={20} />
                <Typography.Text className="text-neutral font-medium">
                  $1,000
                </Typography.Text>
              </Flex>
            </Space>

            <Space direction="vertical" size={0}>
              <Typography.Text type="secondary">Volume</Typography.Text>
              <Flex gap={6}>
                <Typography.Text className="font-medium">
                  10,111
                </Typography.Text>
                <A8TokenIcon size={20} />
                <Typography.Text className="text-neutral font-medium">
                  $1,000
                </Typography.Text>
              </Flex>
            </Space>
          </Space>
          <Link
            href={{
              pathname: APP_ROUTES.COLLECTION_DETAIL,
              query: {
                collectionSlug: nftCollection.slug,
              },
            }}
            target="_blank"
          >
            <Button type="primary" className="font-bold px-4 h-12">
              View
              <OpenNewTabIcon />
            </Button>
          </Link>
        </Flex>
      </Col>
    </Row>
  );
}
